import {ModelConstants} from "./ModelConstants";
import {IAnyModelType, types} from "mobx-state-tree";
import InfoWeatherModel from "./InfoWeatherModel";
import PoIMediaModel from "./PoIMediaModel";
import PoILinkModel from "./PoILink";


const PoIModel = types.model(ModelConstants.PoIModel, {

    id: types.identifierNumber,
    name: types.maybe(types.string),
    label: types.maybe(types.string),
    description: types.maybe(types.string),
    media: types.maybe(types.array(PoIMediaModel)),
    depth: types.optional(types.number, 0),
    entityId: types.maybe(types.number),
    type: types.optional(types.string, ""),
    typeId: types.maybe(types.number),
    lft: types.number,
    rgt: types.number,
    lat: types.maybe(types.number),
    lon: types.maybe(types.number),
    loadsNewScene: types.optional(types.boolean, false),
    asset3DURI: types.maybe(types.string),
    linksToRender: types.maybe(types.array(PoILinkModel)),
    infoWeather: types.maybe(InfoWeatherModel),
    children: types.maybe(types.array(types.late((): IAnyModelType => PoIModel))),
    position: types.maybe(types.frozen()),
    cameraPosition: types.maybe(types.frozen()),
    cameraTargetPosition: types.maybe(types.frozen()),
    starterCameraTargetPosition: types.maybe(types.frozen()),
    starterCameraPosition: types.maybe(types.frozen()),
    dataId: types.maybe(types.number),

    linksAsSource: types.maybe(types.array(PoILinkModel)),
    linksAsTarget: types.maybe(types.array(PoILinkModel)),

    dataPanels: types.maybe(types.array(types.frozen())),

    })
    .actions(self => ({

        setChildren(children: any) {
            self.children = children;
        },

        pushChild(child: any) {
            self.children?.push(child);
        },

        updatePoI3DAsset(uri: string) {
            self.asset3DURI = uri
        }

    }));



export default PoIModel;
