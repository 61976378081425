import {Instance, types} from "mobx-state-tree";
import {ModelConstants} from "../models/ModelConstants";
import ViewModel from "../models/ViewModel";
import NavigationPanelTabModel from "../models/NavigationPanelTabModel";

export const ViewStore = types.model(ModelConstants.ViewModel, {
    view: ViewModel
})

.actions(self => ({

    setCurrentScene(scene: string | undefined) {

        self.view.currentScene = scene;

    },

    setMinimapVisibility(visible: boolean) {

        self.view.showMinimap = visible;

    },

    setWeatherWidgetVisibility(visible: boolean) {

        self.view.showWeatherWidget = visible;

    },

    togglePoIInfo() {
        self.view.showPoIInfo = !self.view.showPoIInfo;
    },

    setShowPoIInfo(show: boolean) {
        self.view.showPoIInfo = show
    },

    setShowPoILinks(show: boolean) {
        self.view.showPoILinks = show
    },

    togglePoIGrafana() {

        self.view.showPoIGrafana = !self.view.showPoIGrafana;

    },

    togglePoINavigationPanel() {
        self.view.showPoINavigation = !self.view.showPoINavigation;
    },

    setPoINavigationDrawer(isDrawer: boolean) {
        self.view.poiNavigationDrawer = isDrawer;
    },

    setRelativePoIDepth(depth: number) {
        self.view.relativePoIDepth = depth;
    },

    setExpandedNodes(nodes: number[]) {
        self.view.expandedNodes.clear()
        nodes.forEach(node => self.view.expandedNodes.push(node))
    },

    toggleExpandedNode(node: number) {
        if (self.view.expandedNodes.includes(node)) {
            self.view.expandedNodes.remove(node)
        } else {
            self.view.expandedNodes.push(node)
        }
    },

    setViewerLoaded(loaded: boolean) {
        self.view.viewerLoaded = loaded;
    },

    setSceneURI(uri: string) {
        self.view.sceneURI = uri;
    },

    setSceneLoaded(loaded: boolean) {
        self.view.sceneLoaded = loaded;
    },

    setEditPoIPositionMode(enabled: boolean) {
        self.view.editPoIPositionMode = enabled;
    },

    setShowPoIMarkers(visible: boolean) {
        self.view.showPoIMarkers = visible;
    },

    addNavigationPanelTab(tab: Instance<typeof NavigationPanelTabModel>) {
        self.view.navigationPanelTabs.push(tab);
    },

    removeNavigationPanelTab(tab: Instance<typeof NavigationPanelTabModel>) {
        self.view.navigationPanelTabs.remove(tab);
    },

    setActiveNavigationPanelTab(tabId: number) {

        if (self.view.activeNavigationPanelTab && self.view.activeNavigationPanelTab.id === tabId) {
            self.view.activeNavigationPanelTab = undefined;

        } else
            self.view.activeNavigationPanelTab = self.view.navigationPanelTabs.find(tab => tab.id === tabId);
    },

    setDataPanelVisibility(visible: boolean) {
        self.view.showDataPanel = visible;
    },

    setDataPanelInfo(info: any) {
        self.view.dataPanelInfo = info;
    }


}));
