import {API_BASE_URL} from "../../config/services";
import axiosInstance from "../../config/services";

export interface AuthParams {

    email: string;
    password: string;
}


export const doLogin = async ({ email, password }: AuthParams) => {
    const formData = new URLSearchParams();
    formData.append('username', email); // API is expecting 'username' instead of 'email'
    formData.append('password', password);

    try {
        const response = await
            axiosInstance.post(`${API_BASE_URL}/auth/login`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response.data;

    } catch (error) {
        console.error(`Error en el proceso de autenticación: `, error);
        throw error;
    }
};

