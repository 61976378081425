import PoIModel from "../../../../data/state/models/PoIModel";
import {clone, Instance} from "mobx-state-tree";
import {ReactUnityEventParameter} from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import "./styles.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faCube,
    faSquarePlus,
    faSquareMinus,
    faServer,
    faMapMarked,
    faInfoCircle,
    faPlayCircle, faVrCardboard,
    faCompass, faDiamond, faArrowAltCircleRight,
    faStarHalfStroke, faStar, faDoorOpen, faDoorClosed, faCircleNodes
} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useEffect, useRef, useState} from "react";
import useLogger, {LogLevel} from "../../../hooks/Logger";
import {Badge, Button, Popover, Tooltip} from "flowbite-react";
import {useStore} from "../../../../data/state/store";
import {observer} from "mobx-react";
import usePoI from "../../../hooks/PoI";
import {ENTER_ON_SELECTED_POI, SHOW_LEAF_POI_MARKER} from "../../../../config/global";
import NavigationPanelTabModel from "../../../../data/state/models/NavigationPanelTabModel";

const COMPONENT_NAME = "PoINode"

interface PoINodeProps {

    poi: Instance<typeof PoIModel>,
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void
}

const PoINode = observer(({poi, unityMessenger}: PoINodeProps) => {

    const {poiStore, viewStore} = useStore()
    const [isExpanded, setIsExpanded] = useState(false)
    const {logger} = useLogger()
    const [isSelected, setIsSelected] = useState(poiStore.selectedPoI?.id === poi.id)
    const [poiDepth] = useState(poi.depth - viewStore.view.relativePoIDepth)
    const {selectPoI, goToPoIPosition, sendPoIList, loadPoIModel, removeMarkers, sendPoIMarker} = usePoI()

    const unityMessengerRef = useRef(unityMessenger)
    unityMessengerRef.current = unityMessenger

    // HANDLERS
    const handleClickOnPoITree = useCallback((event: React.MouseEvent, poi: Instance<typeof PoIModel>) => {


        // Primero, manejadores miscelaneos propios del comportamiento del componente.
        event.stopPropagation() //Evitamos que se propague el evento a otros PoIs

        // Caso particular. En caso de que el PoI ya esté seleccionado y tenga escena 3D propia, vamos a la escena.
        if (poi.id === poiStore.selectedPoI?.id && poi.asset3DURI && ENTER_ON_SELECTED_POI) {
            logger(`PoI ${poi.id} already selected and has its own scene. Going to scene...`, LogLevel.INFO, COMPONENT_NAME)
            handleChangeScene(event, poi);
            return
        }


        selectPoI(poi)

        // 2: Manejamos la carga de la escena del PoI.
        // Caso 1: Sólo pedimos cargar una nueva escena si el PoI de escena no es el PoI de la escena actual.
        if (viewStore.view.currentScene && viewStore.view.currentScene !== poiStore.scenePoI?.asset3DURI) {

            logger(`PoI ${poi.id} loads new scene ${poiStore.scenePoI?.asset3DURI}`, LogLevel.INFO, COMPONENT_NAME)

            loadPoIModel(unityMessengerRef.current, poiStore.scenePoI!).then(() => {
                logger("Asked 3D Viewer to load new scene...", LogLevel.INFO, COMPONENT_NAME)
            })

            // Caso 2: No tenemos que cargar una escena nueva, ya que la escena ya está cargada.
        } else {
            logger(`PoI ${poi.id} scene: ${poi.asset3DURI} is the same as the current scene or has no scene: 
            ${viewStore.view.currentScene}`, LogLevel.INFO, COMPONENT_NAME)
            logger(`Going to PoI position for PoI with id: ${poi.id} instead of loading new scene`, LogLevel.INFO, COMPONENT_NAME)

            // En este caso, sólo tenemos que pedir a la cámara que se posicione en la posición del marcador del PoI.
            goToPoIPosition(unityMessengerRef.current, poi.cameraPosition, poi.cameraTargetPosition).then(() => {
                logger("Asked 3D Viewer to go to PoI position...", LogLevel.INFO, COMPONENT_NAME)
            })

            // Adicionalmente, tenemos que enviar la lista de marcadores descendientes del PoI para la escena actual,
            // pero únicamente si el PoI no tiene su propia escena.
            // Excepcionalmente, enviaremos la lista de PoIs en caso de que el PoI seleccionado sea el PoI raíz.
            console.log(poi.asset3DURI)
            if (!poi.asset3DURI || !poi.loadsNewScene || (poi.id === poiStore.tree?.id)) {

                if (!poi.children || poi.children.length === 0) {
                    sendPoIMarker(unityMessengerRef.current, poi).then(() => {
                        logger(`Asked 3D Viewer to send PoI marker for PoI with id: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
                    })
                } else {
                    sendPoIList(unityMessengerRef.current, poi).then(() => {
                        logger(`Asked 3D Viewer to send PoI list for PoI with id: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
                    })
                }

            } else {
                // En caso contrario, no hay que representar ningún marcador en la escena actual. Nos aseguramos enviando
                // una petición al visor para que no pinte ninguno.
                if (SHOW_LEAF_POI_MARKER) {
                    sendPoIMarker(unityMessengerRef.current, poi).then(() => {
                        logger(`Asked 3D Viewer to send PoI marker for PoI with id: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
                    })
                } else {
                    removeMarkers(unityMessengerRef.current).then(() => {
                        logger("Asked 3D Viewer to remove markers", LogLevel.INFO, COMPONENT_NAME)
                    })
                }
            }
        }

    }, [])

    const handleChangeScene = useCallback((event: React.MouseEvent, poi: Instance<typeof PoIModel>) => {

        event.stopPropagation() //Evitamos que se propague el evento a otros PoIs

        // Marcamos como seleccionado el PoI de la escena a cargar.
        selectPoI(poi)

        // Si el recurso del PoI que solicitamos cargar ya es el recurso cargado, navegamos a la posición INICIAL,
        // y enviamos la lista de marcadores.
        if (viewStore.view.currentScene === poi.asset3DURI) {

            goToPoIPosition(unityMessengerRef.current, poi.starterCameraPosition, poi.starterCameraTargetPosition).then(() => {
                logger(`Asked 3D Viewer to go to starter position of the PoI: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
            })

            sendPoIList(unityMessengerRef.current, poi).then(() => {
                logger(`Asked 3D Viewer to render markers...: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
            })

        } else {
            loadPoIModel(unityMessengerRef.current, poi).then(() => {
                logger(`Model loaded for PoI with id: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
                // Marcamos el PoI seleccionado como PoI de escena
                poiStore.setScenePoI(clone(poi))
                viewStore.setCurrentScene(poi.asset3DURI!)
            })
        }

    }, [])

    const handleExpand = useCallback((event: React.MouseEvent, poi: Instance<typeof PoIModel>) => {
        event.stopPropagation()
        logger(`Expanding PoI with id: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
        logger(`Expanded nodes: ${viewStore.view.expandedNodes}`, LogLevel.INFO, COMPONENT_NAME)
        viewStore.toggleExpandedNode(poi.id)
    }, [])

    const handleMouseEnter = useCallback((poiId: number) => {

        // UnityService.onMouseEnterPoi(unityMessenger, poiId)
        //     .then(() => {
        //         //logger(`Mouse entered PoI with id: ${poiId}`, LogLevel.INFO, COMPONENT_NAME)
        //     })
        //     .catch((error) => {
        //         logger(`Error while sending message to Unity: ${error}`, LogLevel.ERROR)
        //     })
    }, [])

    const handleMouseLeave = useCallback((poiId: number) => {
        // UnityService.onMouseLeavePoi(unityMessenger, poiId)
        //     .then(() => {
        //         logger(`Mouse left PoI with id: ${poiId}`, LogLevel.INFO, COMPONENT_NAME)
        //     })
        //     .catch((error) => {
        //         logger(`Error while sending message to Unity: ${error}`, LogLevel.ERROR)
        //     })
    }, [])

    const handleOpenPoIInfo = useCallback((poi: Instance<typeof PoIModel>) => {

        logger(`Opening PoI Info for PoI with id: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
        viewStore.togglePoIInfo()
    }, [])

    const handleOpenPoILinks = useCallback((poi: Instance<typeof PoIModel>) => {

        logger(`Opening PoI Links for PoI with id: ${poi.id}`, LogLevel.INFO, COMPONENT_NAME)
        viewStore.setShowPoILinks(true)
    }, [])


    const toggleNavigationPanelTab = useCallback((poiId: number) => {

        logger(`Toggling navigation panel tab for PoI with id: ${poiId}`, LogLevel.INFO, COMPONENT_NAME)

        if (viewStore.view.navigationPanelTabs.find(tab => tab.id === poiId)) {

            const poiToRemove = viewStore.view.navigationPanelTabs.find(tab => tab.id === poiId)

            console.log(poiToRemove)

            if (poiToRemove)
                viewStore.removeNavigationPanelTab(poiToRemove)
        } else {

            viewStore.addNavigationPanelTab(NavigationPanelTabModel.create({
                id: poiId,
                name: poiStore.selectedPoI?.name || "PoI " + poiId,
            }))
        }

    }, [])

    // OTHERS
    const getPoIPadding = () => {

        return 1 + "rem"
    }

    // USE EFFECTS

    useEffect(() => {

        setIsSelected(poiStore.selectedPoI?.id === poi.id)
        setIsExpanded(viewStore.view.expandedNodes.includes(poi.id))

        logger(`Expanded nodes: ${viewStore.view.expandedNodes}`, LogLevel.INFO, COMPONENT_NAME)

    }, [poi.id, poiStore.selectedPoI?.id, viewStore.view.expandedNodes]);

    return (
        <>
            <li
                onMouseEnter={() => handleMouseEnter(poi.id)}
                onMouseLeave={() => handleMouseLeave(poi.id)}
                className={`poi-node select-none md:text-xs 
                ${isSelected ? " bg-gray-100" : ""}
                border-b-amber-100 cursor-pointer`}
                style={{paddingLeft: getPoIPadding()}}
                onClick={(event) => handleClickOnPoITree(event, poi)}>

                <div
                    className={"" +
                        "hover:bg-gray-200" +
                        (isSelected ? " bg-amber-100 poi-node-selected" : "") +
                        " poi-node-wrapper grid grid-cols-9 items-center grid-rows-2 grid-flow-col gap-1 p-1.5"}>

                    <div
                        className={"col-span-1 row-span-2 flex items-center justify-center cursor-pointer w-full h-full"}
                        onClick={(event) => handleExpand(event, poi)}>
                        {poi.children && poi.children.length > 0 && (
                            <>
                                {viewStore.view.expandedNodes.includes(poi.id) &&
                                    <FontAwesomeIcon size={"lg"} icon={faSquareMinus} color={"#123456"}/>}
                                {!viewStore.view.expandedNodes.includes(poi.id) &&
                                    <FontAwesomeIcon size={"lg"} icon={faSquarePlus} color={"#123456"}/>}
                            </>
                        )}
                        {(!poi.children || poi.children.length === 0) && (
                            <FontAwesomeIcon size={"xs"} className={"opacity-15"} icon={faCircle}/>
                        )}
                    </div>

                    <div
                        className={`${poi.asset3DURI ? "col-span-7" : "col-span-8"}` +
                            " flex overflow-hidden whitespace-nowrap text-ellipsis pl-1 justify-items-center items-center"}
                        id="poi-name">

                        {poi.name}
                        &nbsp;
                        {poi.children && (
                            <>
                                <Badge
                                    style={{opacity: poi.children.length > 0 ? 1 : 0.3}}
                                    color={"" +
                                        `${poi.children.length > 0 ? "dark" : "gray"}` +
                                        ""}>{poi.children.length}</Badge>
                            </>
                        )}
                    </div>


                    <div className={`${poi.asset3DURI ? "col-span-7" : "col-span-8"}` +
                        " flex justify-start"}
                         id="poi-info-preview">

                        {poi.type === "MARKER" && (
                            <FontAwesomeIcon icon={faCompass} className={"px-1"} color={"#4B5563"}/>
                        )}
                        {poi.type === "ASSET" && (
                            <FontAwesomeIcon icon={faDiamond} className={"px-1"} color={"#4B5563"}/>
                        )}

                        {poi.loadsNewScene === true && (
                            <FontAwesomeIcon
                                icon={faCube} className={"px-1"} color={"#4B5563"}/>
                        )}
                        {poi.type === "SENSOR" && (
                            <Popover
                                placement="right"
                                trigger={"hover"}
                                content={
                                    <>
                                        <h1 className={"text-black"}>Holi soy el contenido del
                                            sensor {poi.entityId}</h1>
                                    </>
                                }>
                                <FontAwesomeIcon icon={faServer} className={"px-1"} color={"#4B5563"}/>
                            </Popover>
                        )}
                        {!!poi.lat && !!poi.lon && (
                            <>
                                <FontAwesomeIcon icon={faMapMarked} className={"px-1"} color={"#4B5563"}/>
                            </>
                        )}

                        {poi.description && (poi.type !== "SENSOR") && (
                            <>
                                <FontAwesomeIcon onClick={() => handleOpenPoIInfo(poi)} icon={faInfoCircle}
                                                 className={"px-1"} color={"#4B5563"}/>

                                {(poi.media && poi.media.length > 0) && (
                                    <FontAwesomeIcon onClick={() => handleOpenPoIInfo(poi)} icon={faPlayCircle}
                                                     className={"px-1"} color={"#4B5563"}/>
                                )}

                                {( (poi.linksAsSource && poi.linksAsSource.length > 0)
                                    || (poi.linksAsTarget && poi.linksAsTarget.length > 0)) && (
                                    <FontAwesomeIcon onClick={() => handleOpenPoILinks(poi)} icon={faCircleNodes}
                                                     className={"px-1"} color={"#4B5563"}/>

                                )}

                            </>
                        )}
                    </div>


                    {(poi.asset3DURI && !(poi.id === poiStore.tree?.id)) && (poi.id === poiStore.selectedPoI?.id) && (
                        <>
                            <div className={"col-span-1 row-span-2 justify-items-center items-center"}
                                 onClick={(event) => handleChangeScene(event, poi)}
                            >

                                {poi.type === "ASSET" && (
                                    <FontAwesomeIcon icon={faVrCardboard} size={"xl"} color={"#023047"}/>
                                )}

                                {poi.type !== "ASSET" && (
                                    <Tooltip content={
                                        `Change view to ${poi.name}`
                                    }>
                                        <FontAwesomeIcon
                                            icon={faDoorOpen} size={"xl"} color={"#023047"}/>

                                    </Tooltip>


                                )}

                            </div>
                        </>
                    )}

                </div>

                {poi.children && (
                    <>
                        {viewStore.view.expandedNodes.includes(poi.id) && (
                            <ul className={""}>
                                {poi.children.map((child: any) => (
                                    <PoINode poi={child} unityMessenger={unityMessenger} key={child.id}/>
                                ))}
                            </ul>
                        )}
                    </>
                )}

            </li>


        </>
    );
});

export default PoINode;
