import axios from "axios";


export const API_HOST = process.env.REACT_APP_BACKEND_URL
export const API_BASE_URL = `${API_HOST}`;

// Crear una instancia de axios
const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

// Interceptor to include the token in the request headers
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
