import {types, flow} from "mobx-state-tree";
import {ModelConstants} from "../models/ModelConstants";
import {AuthParams, doLogin} from "../../services/authService";

export const AuthStore = types.model(ModelConstants.GlobalModel, {

    token: types.maybeNull(types.string),
    refreshToken: types.maybeNull(types.string),
    user: types.maybeNull(types.frozen()),
    isAuthenticated: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false),
})

    .actions(self => ({

        initialize() {
            const token = localStorage.getItem("token");
            if (token) {
                self.token = token;
                self.isAuthenticated = true;
            }
        },

        setCredentials(data: any): number {

            if (data && data.access_token && data.refresh_token) {
                self.token = data.access_token;
                self.refreshToken = data.refresh_token;
                self.isAuthenticated = true;

                try {
                    localStorage.setItem("token", data.access_token);
                    localStorage.setItem("refreshToken", data.refresh_token);
                }
                catch (error) {
                    console.warn(`Error al guardar las credenciales en el almacenamiento local: `, error);
                    return -1;
                }

                return 1;
            } else {
                console.warn(`Bad credentials: `, data);
                return 0;
            }

        },

        login: flow(function* (email, password) {
            self.loading = true;

            const authParams = {
                email: email,
                password: password
            } as AuthParams;

            try {
                return yield doLogin(authParams)

            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }

        }),

        // Cerrar sesión
        logout() {
            self.token = null;
            self.refreshToken = null;
            self.isAuthenticated = false;
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
        },




    }));
