import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";
import AlarmRuleModel from "./AlarmRuleModel";
import SensorModel from "./SensorModel";


const AlarmModel = types.model(ModelConstants.AlarmModel, {

        id: types.identifierNumber,
        condition: types.maybe(types.string),
        status: types.maybe(types.string),
        message: types.maybe(types.string),
        criticality: types.maybe(types.string),
        value: types.maybe(types.number),
        triggeredAt: types.maybe(types.Date),

        alarmRule: AlarmRuleModel,
        sensor: SensorModel

    })
    .actions(self => ({


    }));



export default AlarmModel;
