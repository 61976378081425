
export const ModelConstants = {

    GlobalModel: "GlobalModel",
    AuthModel: "AuthModel",
    AuthStore: "AuthStore",
    LanguageModel: "LanguageModel",
    PoIModel: "PoIModel",
    PoIMediaModel: "PoIMediaModel",
    PoILinkModel: "PoILinkModel",
    PoITypeModel: "PoITypeModel",
    ViewModel: "ViewModel",
    InfoWeatherModel: "InfoWeatherModel",
    MinimapModel: "MinimapModel",
    NavigationPanelTabModel: "NavigationPanelTabModel",
    AlarmModel: "AlarmModel",
    AlarmRuleModel: "AlarmRuleModel",
    SensorModel: "SensorModel",

}
