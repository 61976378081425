import {Instance, types} from "mobx-state-tree";
import {ModelConstants} from "../models/ModelConstants";
import AlarmModel from "../models/AlarmModel"

export const AlarmStore = types.model(ModelConstants.AlarmModel, {

    alarms: types.optional(types.array(AlarmModel), []),
    selectedAlarm: types.maybe(AlarmModel),

})

.actions(self => ({


    setAlarms(alarms: Instance<typeof AlarmModel>[]) {
        this.resetAlarms()

        alarms.forEach((alarm: Instance<typeof AlarmModel>) => {
            this.addAlarm(alarm)
        })
    },

    addAlarm(alarm: Instance<typeof AlarmModel>) {
        self.alarms.push(alarm)
    },

    resetAlarms(){
        self.alarms.clear()
    },

    setAlarmStatus(alarmId: number, status: string) {

        const alarm = self.alarms.find((alarm: Instance<typeof AlarmModel>) => {
            return alarm.id === alarmId
        })

        if (alarm) {
            alarm.status = status
        }

    }

}))

.views(self => ({

    getUnreadAlarms() {

        return self.alarms.filter((alarm: Instance<typeof AlarmModel>) => {
            return alarm.status === "UNREAD" || alarm.status === "unread" || alarm.status === undefined
                || alarm.status === null || alarm.status === "0"
        })

    },

    getReadAlarms() {

        return self.alarms.filter((alarm: Instance<typeof AlarmModel>) => {
            return alarm.status === "READ" || alarm.status === "read" || alarm.status === "1"
        })
    },

    getTotalUnreadAlarms() {
        return this.getUnreadAlarms().length
    },



}))
