import PoIModel from "../data/state/models/PoIModel";
import {clone, Instance} from "mobx-state-tree";

import {PoI, PoI3DViewer} from "../data/types/poi";
import PoILinkModel from "../data/state/models/PoILink";

export const getPoIAscendants = (tree: Instance<typeof PoIModel>, poi: Instance<typeof PoIModel>, includeChild: boolean = true): Instance<typeof PoIModel>[] => {

    let ascendants: Instance <typeof PoIModel>[] = [];

    const traverse = (node: Instance<typeof PoIModel>) => {
        if (node.lft < poi.lft && node.rgt > poi.rgt) {
            ascendants.push(clone(node));
        }

        if (node.children) {
            for (let child of node.children) {
                traverse(child);
            }
        }
    };

    traverse(tree);

    // Adding the selected PoI to the breadcrumb
    if (includeChild)
        ascendants.push(clone(poi));

    return ascendants;
}

export const getParentScenePoI = (tree: Instance<typeof PoIModel>, poi: Instance<typeof PoIModel>): Instance<typeof PoIModel> | undefined => {

        let ascendants: Instance<typeof PoIModel>[] = getPoIAscendants(tree, poi, false);

        if (!ascendants || ascendants.length === 0) return;

        ascendants = ascendants.reverse();

        for (let ascendant of ascendants) {
            if (ascendant.loadsNewScene) {
                return ascendant;
            }
        }
        return undefined;
}


export const convertPoiListTo3DViewer = (poiList: Instance<typeof PoIModel>[]) => {

    let converted_poi_list: PoI3DViewer[] = [];
    poiList.forEach( poi => {
        converted_poi_list.push(poiModelToPoI3DViewer(poi))
    })

    // Primero se convierte la lista de PoIs a un formato que pueda ser interpretado por el visor 3D
    let poisToViewer = { "POIs": converted_poi_list };

    // Segundo lo pasamos a string y lo devolvemos
    return JSON.stringify(poisToViewer)

}

export const convertToPoIMarkerAlert = (poiList: Instance<typeof PoIModel>[]) => {

    let convertedList: any[] = [];

    for (let poi of poiList) {
        convertedList.push({
            id: poi.id,
            color1: {
                r: 255,
                g: 0,
                b: 0,
                a: 0.5
            },
            color2: {
                r: 255,
                g: 255,
                b: 255,
                a: 1
            },
            pauseTime: 0.5
        })
    }

    return convertedList;

}

export const convertPoIListToMarkers = (poiList: any[]) => {

    let markers: Instance<typeof PoIModel>[] = [];

    poiList.forEach( poi => {
        markers.push(poiAPIToPoIModel(poi))
    })

    return markers;
}

export const convertPoILinkTo3DViewer = (link: Instance<typeof PoILinkModel>) => {

    return {
        id: link.id,
        label: link.label,
        color: {
            r: link.color ? link.color.r : 0,
            g: link.color ? link.color.g : 0,
            b: link.color ? link.color.b : 0,
            a: link.color ? link.color.a : 1
        },
        width: link.width ? link.width : 10,
        points: link.points ? link.points : [],

        speed: link.speed,
        visible: link.visible,
        animated: link.animated
    };

}

export const getPoIFromTreeById = (tree: Instance<typeof PoIModel>, id: number): Instance<typeof PoIModel> | undefined => {

    let poi: Instance<typeof PoIModel> | undefined = undefined;

    const traverse = (node: Instance<typeof PoIModel>) => {
        if (node.id === id) {
            poi = clone(node);
        }

        if (node.children) {
            for (let child of node.children) {
                traverse(child);
            }
        }
    };

    traverse(tree);

    return poi;

}

export const getPoIFromEntityId = (tree: Instance<typeof PoIModel>, entityId: number): Instance<typeof PoIModel> | undefined => {

    let poi: Instance<typeof PoIModel> | undefined = undefined;

    const traverse = (node: Instance<typeof PoIModel>) => {
        if (node.entityId === entityId) {
            poi = clone(node);
        }

        if (node.children) {
            for (let child of node.children) {
                traverse(child);
            }
        }
    };

    traverse(tree);

    return poi;


}

export const poiAPIToPoIModel = (poiAPI: any): Instance<typeof PoIModel> => {

    const poiModel= PoIModel.create({
        id: poiAPI.id,
        entityId: poiAPI.entity_id?poiAPI.entity_id:undefined,

        // TYPE
        type: poiAPI.poi_type?.code?poiAPI.poi_type?.code:undefined,
        typeId: poiAPI.poi_type?.id?poiAPI.poi_type?.id:undefined,

        // INFO
        name: poiAPI.poi_info?.name?poiAPI.poi_info?.name:undefined,
        description: poiAPI.poi_info?.description?poiAPI.poi_info?.description:undefined,
        media: poiAPI.poi_info?.media,
        lat: poiAPI.poi_info?.lat,
        lon: poiAPI.poi_info?.lon,

        // DATA
        dataId: poiAPI.poi_data?.id,
        cameraPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.camera_position?.x, y: poiAPI.poi_data.camera_position?.y, z: poiAPI.poi_data.camera_position?.z,
            }: undefined,

        cameraTargetPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.camera_target?.x, y: poiAPI.poi_data.camera_target?.y, z: poiAPI.poi_data.camera_target?.z,
            }: undefined,

        position: poiAPI.poi_data? {
            x: poiAPI.poi_data.poi_position?.x, y: poiAPI.poi_data.poi_position?.y, z: poiAPI.poi_data.poi_position?.z,
            }: undefined,

        starterCameraPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.poi_assets.starter_camera_x_position, y: poiAPI.poi_data.poi_assets.starter_camera_y_position, z: poiAPI.poi_data.poi_assets.starter_camera_z_position
            }: undefined,

        starterCameraTargetPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.poi_assets.starter_camera_target_x_position, y: poiAPI.poi_data.poi_assets.starter_camera_target_y_position, z: poiAPI.poi_data.poi_assets.starter_camera_target_z_position
            }: undefined,

        label: poiAPI.poi_data?.label,
        loadsNewScene: !!poiAPI.poi_data?.poi_assets?.uri,
        asset3DURI: poiAPI.poi_data?.poi_assets?.uri?poiAPI.poi_data?.poi_assets?.uri:undefined,

        linksToRender: poiAPI.poi_data.poi_assets?.rendered_links ? poiAPI.poi_data.poi_assets.rendered_links.map(poiLinkAPIToPoILinkModel) : [],

        linksAsSource: poiAPI.links_as_source ? poiAPI.links_as_source.map(poiLinkAPIToPoILinkModel) : [],
        linksAsTarget: poiAPI.links_as_target ? poiAPI.links_as_target.map(poiLinkAPIToPoILinkModel) : [],

        dataPanels: poiAPI.data_panels,

        // OU
        depth: poiAPI.depth,
        lft: poiAPI.lft,
        rgt: poiAPI.rgt,

        children: []
    });

    if (poiAPI.children) {
        poiAPI.children.forEach((childPoi: any) => {
            const childPoiModel = poiAPIToPoIModel(childPoi);
            poiModel.pushChild(childPoiModel);
        });
    }

    return poiModel;
}

export const poiModelToPoI3DViewer = (poi: Instance <typeof PoIModel>): PoI3DViewer => {

    return {

        id: poi.id,
            poi_type: {
                id: poi.typeId?poi.typeId:null,
                code: poi.type?poi.type:null,
                prefab_ref: poi.type?poi.type:null,
        },
        poi_data: {
                id: poi.dataId?poi.dataId:null,
                label: poi.label?poi.label:null,
                camera_position: {
                    x: poi.cameraPosition?.x,
                    y: poi.cameraPosition?.y,
                    z: poi.cameraPosition?.z
                },
                camera_target: {
                    x: poi.cameraTargetPosition?.x,
                    y: poi.cameraTargetPosition?.y,
                    z: poi.cameraTargetPosition?.z
                },
                poi_position: {
                    x: poi.position?.x,
                    y: poi.position?.y,
                    z: poi.position?.z
                },

        },
        poi_assets: {
            uri: poi.asset3DURI?poi.asset3DURI:null,
            starterCameraXPosition: 0,
            starterCameraYPosition: 0,
            starterCameraZPosition: 0,
            starterCameraTargetXPosition: 0,
            starterCameraTargetYPosition: 0,
            starterCameraTargetZPosition: 0
        }
    }
}

export const poiAPIToPoI = (apiData: any): PoI => {

    return {
        id: apiData.id,
        lft: apiData.lft,
        rgt: apiData.rgt,
        depth: apiData.depth,
        name: apiData.poi_info.name,
        description: apiData.poi_info.description,
        entityId: apiData.entity_id?apiData.entity_id:undefined,
        lat: apiData.poi_info.lat,
        lon: apiData.poi_info.lon,
        type: apiData.poi_type.code,
        loadsNewScene: apiData.poi_data.poi_assets?!!apiData.poi_data.poi_assets.uri:false,
        asset3DURI: apiData.poi_data.poi_assets?apiData.poi_data.poi_assets.uri:undefined,
        media: apiData.poi_info?.media.map((media: any) => { return {url: media.url, name: media.name, id: media.id}}),
        children: apiData.children ? apiData.children.map(poiAPIToPoI) : [],
        cameraPosition: {
            camera_position: { x: apiData.poi_data.camera_position.x, y: apiData.poi_data.camera_position.y, z: apiData.poi_data.camera_position.z},
            camera_target: { x: apiData.poi_data.camera_target.x, y: apiData.poi_data.camera_target.y, z: apiData.poi_data.camera_target.z}
        }
    };

}

export const poiLinkAPIToPoILinkModel = (apiData: any): Instance<typeof PoILinkModel> => {

    return PoILinkModel.create({
        id: apiData.id,

        alias: apiData.alias,
        source_poi: apiData.source_poi,
        target_poi: apiData.target_poi,

        // PoI link data

        width: apiData.data?apiData.data.width:undefined,
        label: apiData.data?apiData.data.label:undefined,
        points: apiData.data?apiData.data.points:undefined,
        color: apiData.data?apiData.data.color:undefined, // RGBA format as JSON
        camera: apiData.data?apiData.data.camera:undefined,

        speed: apiData.data?apiData.data.speed:undefined,
        visible: apiData.data?apiData.data.visible:undefined,
        animated: apiData.data?apiData.data.animated:undefined


    });
}

export const poiModelToPoI = (poiModel: Instance<typeof PoIModel>): PoI => {

    return {
        id: poiModel.id,
        lft: poiModel.lft,
        rgt: poiModel.rgt,
        depth: poiModel.depth,
        entityId: poiModel.entityId,
        name: poiModel.name,
        media: poiModel.media?.map((media) => { return {url: media.url, name: media.name, id: media.id}}),
        description: poiModel.description,
        lat: poiModel.lat,
        lon: poiModel.lon,
        type: poiModel.type,
        loadsNewScene: poiModel.loadsNewScene,
        asset3DURI: poiModel.asset3DURI,
        children: poiModel.children?.map(poiModelToPoI),
        cameraPosition: {
            camera_position: {x: poiModel.cameraPosition.camera_position[0], y: poiModel.cameraPosition.camera_position[1], z: poiModel.cameraPosition.camera_position[2]},
            camera_target: {x: poiModel.cameraPosition.camera_target[0], y: poiModel.cameraPosition.camera_target[1], z: poiModel.cameraPosition.camera_target[2]}
        }

    };
}

export const getPoITreeSensors = (tree: Instance<typeof PoIModel>) => {

    // Search in the tree of pois all the sensors by its type and return an array with the sensors

    let sensors: Instance<typeof PoIModel>[] = [];

    const traverse = (node: Instance<typeof PoIModel>) => {
        if (node.type === "SENSOR") {
            sensors.push(clone(node));
        }

        if (node.children) {
            for (let child of node.children) {
                traverse(child);
            }
        }
    };

    traverse(tree);

    return sensors;
}
