import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {useStore} from "../../../data/state/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCogs
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css"
import useLogger, {LogLevel} from "../../hooks/Logger";

interface DataPanelWidgetProps {

    colSpan?: number,
    rowSpan?: number,
    colStart?: number,
    rowStart?: number
}


const DataPanelWidget = observer((
    {colSpan, rowSpan, colStart, rowStart}: DataPanelWidgetProps
) => {


    const { viewStore} = useStore()
    const [data, setData] = useState(viewStore.view.dataPanelInfo)

    useEffect(() => {

        setData(viewStore.view.dataPanelInfo)

    }, );


    return (
        <>
            <div
                className={"m-1.5"}
                style={{
                    pointerEvents: "auto",
                    gridColumnStart: colStart,
                    gridColumnEnd: `span ${colSpan}`,
                    gridRowStart: rowStart,
                    gridRowEnd: `span ${rowSpan}`,
                    visibility: viewStore.view.dataPanelInfo ? "visible" : "hidden",
                }}
                id={"detailed-panel"}>


                {data && (data?.panelUrl || data?.panelQuickviewUrl) && (
                    <iframe title={`Panel-${data?.title}`}
                            className={"w-full h-full"}
                            src={data.panelUrl ? data.panelUrl : data.panelQuickviewUrl}
                            frameBorder="0"

                    ></iframe>
                )}

            </div>
        </>
    )
});

export default DataPanelWidget;
