// @ts-ignore
import defaultLogo from "../../../assets/multimedia/imgs/logos/principal_negativo.svg";
import {Avatar, Button, Dropdown, Navbar} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCube, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {LanguageSelector} from "./LanguageSelector/LanguageSelector";
import AlarmViewer from "./AlarmViewer/AlarmViewer";
import {useStore} from "../../../data/state/store";
import {useNavigate} from "react-router-dom";
import {SCREEN_PATHS} from "../../../config/paths";


export const TopMenu = () => {

    const {authStore} = useStore();
    const navigate = useNavigate();

    const handleLogout = () => {

        authStore.logout();

        navigate(SCREEN_PATHS.LOGIN)

    }


    return (
        <>
            <div id={"top-menu-wrapper"} className={"py-2.5 z-30 flex justify-items-end bg-gray-800 text-white w-full"}>

                <Navbar fluid rounded className={"bg-transparent w-full"}>
                    <Navbar.Brand className={"mr-2.5 items-center flex justify-items-center"}>

                        <img src={defaultLogo} className="mr-6 h-6 sm:h-9" alt="TwinHub Logo"/>
                        <span className="self-center items-center flex whitespace-nowrap text-xl font-semibold dark:text-white">
                            <FontAwesomeIcon icon={faCube}/>
                            &nbsp;
                            TwinHub Viewer
                            &nbsp;
                        </span>
                    </Navbar.Brand>
                    <div className="flex md:order-2">
                        <Dropdown
                            arrowIcon={false}
                            inline
                            label={
                                <Avatar alt="User settings" rounded />
                            }
                        >
                            <Dropdown.Header>
                                <span className="block text-sm">User Visitant</span>
                            </Dropdown.Header>
                            {/*<Dropdown.Item>*/}
                            {/*    <LanguageSelector />*/}

                            {/*</Dropdown.Item>*/}
                            {/*<Dropdown.Item>*/}
                            {/*    <AlarmViewer />*/}
                            {/*</Dropdown.Item>*/}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={ () => handleLogout() }>
                                <FontAwesomeIcon icon={faRightFromBracket}/>&nbsp;
                                Sign out</Dropdown.Item>
                        </Dropdown>
                        <Navbar.Toggle />
                    </div>

                </Navbar>
            </div>

        </>
    );
};
