import {useStore} from "../../../data/state/store";
import {observer} from "mobx-react";
import {Navigate} from "react-router-dom";
import React from "react";

interface PrivateRouteProps {
    component: React.ComponentType;
}

export const PrivateRoute = observer(({component: Component}: PrivateRouteProps) => {
    const {authStore} = useStore();

    if (!authStore.isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return <Component />;
});
