import {useStore} from "../../../data/state/store";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import "./styles.css"
import {observer} from "mobx-react";
import {faCogs} from "@fortawesome/free-solid-svg-icons";
import useLogger, {LogLevel} from "../../hooks/Logger";

interface DataPanelProps {
    id: number
    panelUrl: string
    title: string
    panelQuickviewUrl?: string
    entityProps?: any
}


const DataPanel = observer(({id, panelUrl, title, panelQuickviewUrl, entityProps}: DataPanelProps) => {

    const {viewStore} = useStore()
    const [isLoaded, setIsLoaded] = useState(false)
    const [isError, setIsError] = useState(false)
    const {logger} = useLogger()


    const handleClickOnPanel = () => {

        let dataPanelInfo = {
            id: id,
            title: title,
            panelUrl: panelUrl,
            panelQuickviewUrl: panelQuickviewUrl
        }

        if (viewStore.view.dataPanelInfo?.id === id) {
            viewStore.setDataPanelInfo(undefined)
        } else viewStore.setDataPanelInfo(dataPanelInfo)

    }

    const handleError = (event: any) => {
        setIsError(true)
        logger(`Error loading panel: ${event.target.src}`, LogLevel.ERROR)
    }

    const handleLoad = (event: any) => {
        setIsLoaded(true)
        logger(`Panel loaded: ${event.target.src}`, LogLevel.INFO)
        // TODO: Provisional until Grafana releases a fix for the refresh issue.
        // Autoreloads the iframe every 5 seconds with an interval changing the src attribute

        setTimeout(() => {
            event.target.src = panelQuickviewUrl
        }, 30000)

    }


    /*
    * Replace params in the quickview URL with the entity properties in case they are present.
    * Otherwise, return the URL as is.
     */
    const getPanelQuickViewUrl = () => {

        if (!panelQuickviewUrl) return ""

        // Look for params between %% in the URL
        let matches = panelQuickviewUrl.match(/%(.*?)%/g)

        if (!matches) return panelQuickviewUrl

        matches.forEach((match) => {
            let param = match.replace(/%/g, "")
            let value = entityProps[param]

            // Ñapa para la demo de mañana. Vamos a hacer un intercambio de ids
            console.log("Original PoI Id: ", value)
            value = (value - 5887) + 1

            console.log("New PoI Id: ", value)

            panelQuickviewUrl = panelQuickviewUrl!.replace(match, value)
            console.log("New url:", panelQuickviewUrl)
        })

    }


    return (
        <>
            {panelQuickviewUrl && (

                <div className={"w-1/3 h-48 flex-grow relative"}>

                    {!isLoaded && (
                        <>
                            <div
                                className={"absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50 flex justify-center items-center"}>
                                <FontAwesomeIcon icon={faCogs} spin={true} size={"2x"} color={"#fff"}/>
                            </div>
                        </>
                    )}


                    <iframe title={`Panel-${id}`}
                            id={`panel-${id}`}
                            className={"w-full h-full"}
                            src={getPanelQuickViewUrl()}
                            frameBorder="0"
                            onError={handleError}
                            onLoad={handleLoad}></iframe>



                    <div

                        className={`absolute bottom-0 right-0 opacity-50 bg-gray-800 text-white p-1.5 cursor-pointer hover:opacity-100 
                        ${isLoaded ? "" : "hidden"}}\`
                        ${viewStore.view.dataPanelInfo?.id === id ? "inverted" : "regular"}}`}
                        onClick={() => handleClickOnPanel()}>

                        <FontAwesomeIcon icon={faUpRightFromSquare}/>

                    </div>

                </div>

            )
            }

        </>
    );
});

export default DataPanel;
