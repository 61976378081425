import {useEffect, useState} from "react";
import {useStore} from "../../../../../data/state/store";
import {observer} from "mobx-react";
import DataPanel from "../../../DataMenu/DataPanel";



const PoIGrafanaData = observer(() => {

    const {viewStore, poiStore} = useStore()
    const [isOpen, setIsOpen] = useState(viewStore.view.showPoIGrafana)

    const handleClose = () => {
        viewStore.togglePoIGrafana()
    }

    useEffect(() => {
        setIsOpen(viewStore.view.showPoIGrafana)
    }, [viewStore.view.showPoIGrafana]);

    return (
        <>
            <div className={"p1 flex flex-wrap"}>

                {poiStore.selectedPoI && poiStore.selectedPoI.dataPanels && poiStore.selectedPoI.dataPanels.map((panel, index) => {
                    return (
                        <DataPanel
                            id={panel.id}
                            key={index} panelUrl={panel.url} title={panel.title} panelQuickviewUrl={panel.quickview_url} entityProps={poiStore.selectedPoI}/>
                    )
                })}
            </div>

        </>
    );
});

export default PoIGrafanaData;
