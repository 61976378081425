import {Drawer} from "flowbite-react";
import {useEffect, useState} from "react";
import {useStore} from "../../../../../data/state/store";
import {observer} from "mobx-react";
import {faCaretLeft} from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PoILinks = observer(() => {

    const {viewStore, poiStore} = useStore()
    const [isOpen, setIsOpen] = useState(viewStore.view.showPoILinks)

    const handleClose = () => {
        viewStore.setShowPoILinks(false)
    }


    useEffect(() => {
        setIsOpen(viewStore.view.showPoILinks)
        viewStore.setShowPoIInfo(false)

    }, [viewStore.view.showPoILinks]);

    return (
        <>
            <Drawer
                backdrop={false}
                style={{zIndex: 1000}}
                title={"PoI links"}
                className={"" +
                    "bg-gray-800 text-white " +
                    "lg:w-1/3 sm:w-2/3"}
                open={isOpen} onClose={handleClose}>
                <Drawer.Items className={"p-3"}>

                    <div className={"mb-2"}>
                        <FontAwesomeIcon icon={faCaretLeft} size={"2x"} onClick={() => handleClose()}/>
                    </div>

                    <h2>Enlaces desde este PoI:</h2>

                    {poiStore.selectedPoI?.linksAsSource?.map(link => (
                        <>
                            <p>{link.alias}</p>
                        </>
                    ))}

                    <h2>Enlaces hacia este PoI:</h2>


                    {poiStore.selectedPoI?.linksAsTarget?.map(link => (
                        <>
                            <p>{link.alias}</p>
                        </>
                    ))}
                </Drawer.Items>

            </Drawer>

        </>
    );
});

export default PoILinks;
