import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";
import NavigationPanelTabModel from "./NavigationPanelTabModel";

const ViewModel = types.model(ModelConstants.ViewModel, {

    // 3d scene
    viewerLoaded: types.optional(types.boolean, false),
    sceneURI: types.maybe(types.string),
    sceneLoaded: types.optional(types.boolean, false),
    currentScene: types.maybe(types.string),

    // PoIInfo panel
    showPoIInfo: types.optional(types.boolean, false),

    // PoILinks panel
    showPoILinks: types.optional(types.boolean, false),

    // PoIGrafana panel
    showPoIGrafana: types.optional(types.boolean, false),

    //  PoI Navigation panel
    poiNavigationDrawer: types.optional(types.boolean, false),
    showPoINavigation: types.optional(types.boolean, false),
    relativePoIDepth: types.optional(types.number, 0),
    expandedNodes: types.optional(types.array(types.number), []),
    navigationPanelTabs: types.optional(types.array(NavigationPanelTabModel), []),
    activeNavigationPanelTab: types.maybe(types.reference(NavigationPanelTabModel)),

    // Toolset and widgets
    showMinimap: types.optional(types.boolean, true),
    showWeatherWidget: types.optional(types.boolean, true),
    editPoIPositionMode: types.optional(types.boolean, false),
    showPoIMarkers: types.optional(types.boolean, true),

    // Data panel widget
    showDataPanel: types.optional(types.boolean, false),
    dataPanelInfo: types.maybe(types.frozen()),



});

export default ViewModel;
