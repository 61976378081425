import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";


const AlarmRuleModel = types.model(ModelConstants.AlarmRuleModel, {

    id: types.identifierNumber,
    condition: types.maybe(types.string),
    message: types.maybe(types.string),
    criticality: types.maybe(types.string),
    threshold: types.maybe(types.number),

})
    .actions(self => ({


    }));



export default AlarmRuleModel;
