import {useStore} from "../../../../data/state/store";
import {Badge} from "flowbite-react";
import "./styles.css"

interface SceneTitleProps {

    title: string
}

export const SceneTitle = (
    {title}: SceneTitleProps
) => {

    const {poiStore} = useStore()

    return (
        <>
            <div className={"select-none flex items-center justify-start"}>
                <h1 className={"poi-title text-white opacity-80 text-4xl mr-2.5"}>
                    {title}
                </h1>

                {poiStore.scenePoI?.type === "ASSET" && <Badge className={"justify-items-center"} color={"purple"}>Detailed model</Badge>}
                {poiStore.scenePoI?.type === "MARKER" && <Badge className={"justify-items-center"} color={"blue"}>Scenario</Badge>}

            </div>


        </>
    );
};
