import {API_BASE_URL} from "../../config/services";
import axiosInstance from "../../config/services";

interface ShortlinkParams {
    code: string,
}

export const getShortlinkByCode = async ({code}: ShortlinkParams) => {

    try {
        const response = await axiosInstance.get(`${API_BASE_URL}/shortlink`,
            {
                params: {
                    code
                }
            });

        return response.data;
    } catch (error) {
        console.error(`Error obteniendo el shortlink.`, error);
        throw error;
    }
}

export const createShortlink = async (data: any) => {

    const newShortlink = {
        data: data
    }

    try {
        const response = await axiosInstance.post(`${API_BASE_URL}/shortlink`, newShortlink);

        return response.data;

    } catch (error) {
        console.error(`Error cambiando el estado de la alarma.`, error);
        throw error;
    }

}
