import {types} from "mobx-state-tree";
import {ModelConstants} from "./ModelConstants";

const PoILinkModel = types.model(ModelConstants.PoILinkModel, {

    id: types.identifierNumber,
    alias: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
    width: types.maybeNull(types.number),
    source_poi: types.maybeNull(types.number),
    target_poi: types.maybeNull(types.number),
    color: types.maybeNull(types.frozen()),
    points: types.maybeNull(types.array(types.frozen())),
    camera: types.maybeNull(types.frozen()),

    speed: types.maybeNull(types.number),
    visible: types.maybeNull(types.boolean),
    animated: types.maybeNull(types.boolean),


});

export default PoILinkModel;
