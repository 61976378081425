import axiosInstance, {API_BASE_URL} from "../../config/services";

interface MeasurementParams {

    page_limit: number,
    page_number: number,
    sort_by: string,
    sort_order: string,
    entity_ids: number[] | null,
    sensors_id: number[] | null,
    raw: boolean
}

export const getMeasurementsByCriteria = async ({page_limit, page_number, sort_by, sort_order, entity_ids, sensors_id, raw}: MeasurementParams) => {

    const params = {
        page_limit,
        page_number,
        sort_by,
        sort_order,
        sensors_id,
        entity_ids,
        raw
    }

    try {
        const response = await axiosInstance.get(`${API_BASE_URL}/measurement`,
            {params});

        return response.data;
    } catch (error) {
        console.error(`Error obteniendo las mediciones con los parámetros ${params}`, error);
        throw error;
    }
}
