import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";



const SensorModel = types.model(ModelConstants.SensorModel, {

        id: types.identifierNumber,
        name: types.maybe(types.string),


    })
    .actions(self => ({


    }));



export default SensorModel;
