import {Button, Carousel, Drawer} from "flowbite-react";
import {useEffect, useState} from "react";
import {useStore} from "../../../../../data/state/store";
import {observer} from "mobx-react";
import {CAROUSEL_SLIDE_DURATION} from "../../../../../config/global";
import {PoIMedia} from "../PoIMedia/PoIMedia";
import PoIMap from "../../../PoIMinimapWidget/PoIMinimapWidget";
import {faCaretLeft} from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PoIInfo = observer(() => {

    const {viewStore, poiStore} = useStore()
    const [isOpen, setIsOpen] = useState(viewStore.view.showPoIInfo)
    const [hasMedia, setHasMedia] = useState(false)

    const handleClose = () => {
        viewStore.togglePoIInfo()
    }

    const handleSlideChange = () => {
        const videos = document.querySelectorAll('video')
        const audios = document.querySelectorAll('audio')
        videos.forEach(video => video.pause())
        audios.forEach(audio => audio.pause())
    }

    useEffect(() => {
        setIsOpen(viewStore.view.showPoIInfo)

        // Preventive close of the other drawer for PoI links
        viewStore.setShowPoILinks(false)

        const mediaFiles = poiStore.selectedPoI?.media?.length

        if (mediaFiles && mediaFiles > 0)
            setHasMedia(true)


    }, [viewStore.view.showPoIInfo]);

    return (
        <>
            <Drawer
                backdrop={false}
                style={{zIndex: 1000}}
                title={"PoI Info"}
                className={"" +
                    "bg-gray-800 text-white " +
                    "lg:w-1/3 sm:w-2/3"}
                open={isOpen} onClose={handleClose}>
                <Drawer.Items className={"p-3"}>

                    <div className={"info-header pb-2"}>
                        <FontAwesomeIcon size={"2x"} onClick={() => handleClose()} icon={faCaretLeft} />
                    </div>

                    {hasMedia && (
                        <Carousel
                            className={"mb-5"}
                            indicators={false}
                            pauseOnHover={true}
                            onSlideChange={handleSlideChange}
                            slideInterval={CAROUSEL_SLIDE_DURATION}>

                            {poiStore.selectedPoI?.media?.map((media, index) => (
                                <PoIMedia key={media.id} url={media.url} name={media.name}/>

                            ))}

                        </Carousel>
                    )}



                    <h2 className="text-2xl font-semibold">
                        {poiStore.selectedPoI?.name}
                    </h2>
                    <p>
                        {poiStore.selectedPoI?.description}
                    </p>

                </Drawer.Items>

            </Drawer>

        </>
    );
});

export default PoIInfo;
