import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMapLocation, faCloudSun, faCameraRotate, faCameraRetro,
    faEye, faEyeSlash,
    faLayerGroup, faMarker, faFolderTree, faShare, faLink
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import {useStore} from "../../../data/state/store";
import {UnityService} from "../../../data/services/unityService";
import useLogger, {LogLevel} from "../../hooks/Logger";
import {Checkbox, Dropdown, Label} from "flowbite-react";
import {useCallback, useState} from "react";
import {DEV_MODE, ROOT_POI_ID} from "../../../config/global";
import usePoI from "../../hooks/PoI";
import {convertPoiListTo3DViewer, poiAPIToPoIModel} from "../../../utils/poiUtils";
import {clone, Instance} from "mobx-state-tree";
import {getPoIById, savePoIData} from "../../../data/services/poiService";
import PoIModel from "../../../data/state/models/PoIModel";
import {UNITY_FUNCTION_LISTENERS} from "../../../config/unity";
import {ReactUnityEventParameter} from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import {createShortlink} from "../../../data/services/shortlinkService";
import {PoIDataUpdate} from "../../../data/types/poi";

interface ThreeDToolsetProps {

    colSpan?: number,
    rowSpan?: number,
    colStart?: number,
    rowStart?: number,
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
    addEventListener: (eventName: string, callback: (event: any) => void) => void,
    removeEventListener: (eventName: string, callback: (event: any) => void) => void
}

const COMPONENT_NAME = "ThreeDToolset"

const ThreeDToolset = observer(({colSpan, rowSpan, colStart, rowStart, unityMessenger,
                                    removeEventListener, addEventListener}: ThreeDToolsetProps) => {


            const {viewStore, poiStore} = useStore();
            const {logger} = useLogger();
            const [connectionsLayer, setConnectionsLayer] = useState(true)
            const {sendPoIList, removeMarkers} = usePoI()

            const reloadRootPoI = () => {

                const params = {
                    id: ROOT_POI_ID,
                    max_depth: undefined
                }

                getPoIById(params).then((response) => {

                    const tree: Instance<typeof PoIModel> = poiAPIToPoIModel(response);

                    poiStore.setPoITree(tree); // Establecemos el PoI raíz
                    viewStore.setRelativePoIDepth(tree.depth) // Establecemos la profundidad relativa del PoI raíz

                });
            }

            const toggleMarkersVisibility = () => {

                logger("Toggling PoI markers visibility", LogLevel.INFO, COMPONENT_NAME)
                viewStore.setShowPoIMarkers(!viewStore.view.showPoIMarkers)

                if (viewStore.view.showPoIMarkers) {
                    if (!poiStore.selectedPoI?.loadsNewScene || poiStore.selectedPoI?.id === poiStore.tree?.id ||
                        (poiStore.selectedPoI.id === poiStore.scenePoI?.id)) {
                        logger("Selected PoI does not load new scene or is the root PoI, sending PoI list", LogLevel.INFO, COMPONENT_NAME)
                        sendPoIList(unityMessenger, poiStore.selectedPoI!).then(r => {
                            logger("Scene PoI list sent", LogLevel.INFO, COMPONENT_NAME)
                        })
                    } else {
                        logger("Selected PoI loads its own scene or is the root PoI, not sending PoI list", LogLevel.WARNING)
                        removeMarkers(unityMessenger).then(r => {
                            logger("Markers removed from scene", LogLevel.INFO, COMPONENT_NAME)
                        })
                    }

                } else {
                    logger("Selected PoI loads its own scene or is the root PoI, not sending PoI list", LogLevel.WARNING)
                    removeMarkers(unityMessenger).then(r => {
                        logger("Markers removed from scene", LogLevel.INFO, COMPONENT_NAME)
                    })
                }
            }

            const toggleMinimap = () => {
                viewStore.setMinimapVisibility(!viewStore.view.showMinimap)
            }

            const toggleWeatherWidget = () => {

                viewStore.setWeatherWidgetVisibility(!viewStore.view.showWeatherWidget)
            }

            const setCameraAndPosition = (starter: boolean) => {

                addEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleGetCameraPositions)


                poiStore.setSaveStarterPosition(starter)

                UnityService.getCameraPositions(unityMessenger).then(r => {
                    logger("Camera positions: " + r, LogLevel.INFO, COMPONENT_NAME)
                })
            }

            const setLinkCameraPosition = () => {

                addEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleGetLinkCameraPosition)

                UnityService.getCameraPositions(unityMessenger).then(r => {
                    logger("Camera positions requested")
                })
           }

            const toggleLayer = (layerName: string) => {

                UnityService.toggleObject(unityMessenger, layerName).then(r => {
                    logger("Connections toggled: " + r, LogLevel.INFO, COMPONENT_NAME)
                    setConnectionsLayer(!connectionsLayer)

                })
            }

            const togglePoIEditor = () => {

                if (!poiStore.selectedPoI) {
                    logger("No PoI selected", LogLevel.ERROR)
                    return
                }

                viewStore.setEditPoIPositionMode(!viewStore.view.editPoIPositionMode)

                // First we have to send the JSON with only the selected PoI to the viewer
                if (viewStore.view.editPoIPositionMode) {

                    // Necesitamos inyectar la referencia al recurso de la escena actual para el visor.
                    const poiToEdit = clone(poiStore.selectedPoI)

                    poiToEdit.updatePoI3DAsset(viewStore.view.currentScene!)

                    const poiList = convertPoiListTo3DViewer([poiToEdit])

                    UnityService.sendPoIList(unityMessenger, poiList).then(r => {
                        logger("PoI JSON sent for the selected PoI with Id " + poiStore.selectedPoI?.id, LogLevel.INFO, COMPONENT_NAME)
                    })

                    UnityService.togglePoIEditor(unityMessenger, poiStore.selectedPoI?.id!).then(r => {
                        logger("PoI editor toggled: " + r, LogLevel.INFO, COMPONENT_NAME)
                    })

                    // In case we are not in edit mode, we have to send the whole children PoI list
                } else {

                    UnityService.togglePoIEditor(unityMessenger, poiStore.selectedPoI?.id!).then(r => {
                        logger("PoI editor toggled: " + r, LogLevel.INFO, COMPONENT_NAME)
                    })

                    sendPoIList(unityMessenger, poiStore.selectedPoI).then(r => {
                        logger("PoI JSON list sent for the selected PoI with Id " + poiStore.selectedPoI?.id, LogLevel.INFO, COMPONENT_NAME)
                    })
                }

                logger("PoI position editor " + (viewStore.view.editPoIPositionMode ? "enabled" : "disabled"), LogLevel.INFO, COMPONENT_NAME)

            }

            const generateShortlink = () => {
                // Añadimos el listener para recibir esta petición, si la llamada se realizó correctamente.
                addEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleCameraPositionForShortlink)

                UnityService.getCameraPositions(unityMessenger).then(r => {
                    logger("Camera positions requested")
                })
            }

            const handleCameraPositionForShortlink = (data: ReactUnityEventParameter) => {

                logger("Current selected PoI: " + poiStore.selectedPoI?.id, LogLevel.INFO, COMPONENT_NAME)
                logger("Current camera position: ", LogLevel.INFO, COMPONENT_NAME)
                console.log(data)

                if (!poiStore.selectedPoI) {
                    logger("No PoI selected", LogLevel.ERROR, COMPONENT_NAME)
                    return
                }

                const shortLinkData = {
                    poiId: poiStore.selectedPoI?.id,
                    cameraInfo: data
                }

                createShortlink(shortLinkData).then(r => {
                    logger("Shortlink created: " + r, LogLevel.INFO, COMPONENT_NAME)
                })

                // Una vez manejada la petición, eliminamos el listener
                removeEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleCameraPositionForShortlink)
            }

            const handleGetCameraPositions = useCallback((data: ReactUnityEventParameter) => {

                logger("Getting camera positions: " + data, LogLevel.INFO, COMPONENT_NAME)

                // Una vez manejada la petición, eliminamos el listener
                removeEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleGetCameraPositions)

                if (data === null || data === undefined) return;

                if (typeof data === "string") {

                    const cameraPositions = JSON.parse(data)

                    let positions: PoIDataUpdate

                    if (!poiStore.saveStarterPosition) {
                        logger("Saving camera and target position", LogLevel.INFO, COMPONENT_NAME)
                        positions = {
                            label: poiStore.selectedPoI?.name!,
                            camera_position: {
                                x: cameraPositions.camera_position.x,
                                y: cameraPositions.camera_position.y,
                                z: cameraPositions.camera_position.z
                            },
                            camera_target: {
                                x: cameraPositions.camera_target.x,
                                y: cameraPositions.camera_target.y,
                                z: cameraPositions.camera_target.z
                            },
                            poi_position: {
                                x: cameraPositions.camera_target.x,
                                y: cameraPositions.camera_target.y+15,
                                z: cameraPositions.camera_target.z
                            },
                            poi_assets: {}
                        } as PoIDataUpdate
                    }

                    else {
                        logger("Saving starter camera position and target", LogLevel.INFO, COMPONENT_NAME)
                        positions = {

                            label: poiStore.selectedPoI?.name!,

                            camera_position: null,
                            camera_target: null,
                            poi_position: null,

                            poi_assets: {
                                starter_camera_target_x_position: cameraPositions.camera_target.x,
                                starter_camera_target_y_position: cameraPositions.camera_target.y,
                                starter_camera_target_z_position: cameraPositions.camera_target.z,

                                starter_camera_x_position: cameraPositions.camera_position.x,
                                starter_camera_y_position: cameraPositions.camera_position.y,
                                starter_camera_z_position: cameraPositions.camera_position.z
                            }
                        } as PoIDataUpdate
                    }

                    savePoIData(poiStore.selectedPoI?.id!, positions).then(r =>
                        logger("Camera positions saved: " + r, LogLevel.INFO, COMPONENT_NAME)
                    )
                }

            }, [poiStore.saveStarterPosition])

            const handleGetLinkCameraPosition = (data: ReactUnityEventParameter) => {

                console.log(data);

                removeEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleGetLinkCameraPosition)




            }


            return (
                <>

                    <div style={{

                        visibility: viewStore.view.sceneLoaded ? "visible" : "hidden",
                        pointerEvents: "auto",
                        gridColumnStart: colStart,
                        gridColumnEnd: `span ${colSpan}`,
                        gridRowStart: rowStart,
                        gridRowEnd: `span ${rowSpan}`,
                    }}>

                        <div className={"m-1.5 flex"}>

                            <div className={"bg-gray-800 bg-opacity-30"}>

                                <div className={"toolset-tool bg-gray-800 p-2"} onClick={() => toggleMinimap()}>
                                    <FontAwesomeIcon icon={faMapLocation} size={"sm"} color={"white"}/>
                                </div>

                                <div className={"toolset-tool bg-gray-800 p-2"} onClick={() => toggleWeatherWidget()}>
                                    <FontAwesomeIcon icon={faCloudSun} size={"sm"} color={"white"}/>
                                </div>

                                <div className={"toolset-tool bg-gray-800 p-2"}
                                     onClick={() => toggleMarkersVisibility()}>
                                    <FontAwesomeIcon icon={
                                        viewStore.view.showPoIMarkers ? faEye : faEyeSlash
                                    } size={"sm"} color={"white"}/>
                                </div>

                                {DEV_MODE && (
                                    <>
                                        <div className={"toolset-tool bg-gray-800 p-2"}
                                             onClick={() => setCameraAndPosition(false)}>
                                            <FontAwesomeIcon icon={faCameraRotate} size={"sm"} color={"white"}/>
                                        </div>

                                        <div className={"toolset-tool bg-gray-800 p-2"}
                                             onClick={() => setCameraAndPosition(true)}>
                                            <FontAwesomeIcon icon={faCameraRetro} size={"sm"} color={"white"}/>
                                        </div>

                                        <div className={"toolset-tool bg-gray-800 p-2"}
                                             onClick={() => togglePoIEditor()}>
                                            <FontAwesomeIcon icon={faMarker} size={"sm"} color={"white"}/>
                                        </div>

                                        <div className={"toolset-tool bg-gray-800 p-2"}
                                             onClick={() => reloadRootPoI()}>
                                            <FontAwesomeIcon icon={faFolderTree} size={"sm"} color={"white"}/>
                                        </div>

                                        <div className={"toolset-tool bg-gray-800 p-2"}
                                             onClick={() => setLinkCameraPosition()}>
                                            <FontAwesomeIcon icon={faLink} size={"sm"} color={"white"}/>
                                        </div>

                                    </>
                                )}


                                <Dropdown
                                    dismissOnClick={false}
                                    label={""}
                                    renderTrigger={() =>
                                        <div className={"toolset-tool bg-gray-800 p-2"}>
                                            <FontAwesomeIcon icon={faLayerGroup} size={"sm"} color={"white"}/>
                                        </div>
                                    } placement="right">
                                    <Dropdown.Item
                                        className={"cursor-default hover:bg-transparent"}>

                                        <Checkbox id={"connections"}
                                                  checked={connectionsLayer}
                                                  onChange={
                                                      () => {
                                                          toggleLayer("conections")
                                                      }}>
                                        </Checkbox>
                                        &nbsp;
                                        <Label htmlFor="connections">Connections</Label>

                                    </Dropdown.Item>

                                </Dropdown>


                                <div className={"toolset-tool bg-gray-800 p-2"}
                                     onClick={() => generateShortlink()}>
                                    <FontAwesomeIcon icon={faShare} size={"sm"} color={"white"}/>
                                </div>


                            </div>

                        </div>


                    </div>


                </>
            );
        }
    )
;

export default ThreeDToolset;
