import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCompass, faServer, faDiamond, faCube, faMapMarked
    , faInfoCircle, faPlayCircle, faCircleNodes
} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage} from "react-intl";
import {Badge, Tooltip} from "flowbite-react";

export const PoIMenuLegend = () => {
    return (
        <>
            <div className={"w-full flex justify-center"}>

                <Badge color="indigo">
                    <FormattedMessage id="poi.legend.title"/>
                </Badge>

                <div className={"px-3 pb-1.5"}>

                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.important-location"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faCompass}/>
                    </Tooltip>
                </div>
                <div className={"px-3"}>
                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.important-asset"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faDiamond}/>
                    </Tooltip>
                </div>
                <div className={"px-3"}>
                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.monitoring-data"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faServer}/>
                    </Tooltip>
                </div>
                <div className={"px-3"}>
                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.3d-scene"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faCube}/>
                    </Tooltip>
                </div>
                <div className={"px-3"}>
                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.links"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faCircleNodes}/>
                    </Tooltip>
                </div>
                <div className={"px-3"}>
                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.geolocation"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faMapMarked}/>
                    </Tooltip>
                </div>
                <div className={"px-3"}>
                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.additional-info"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faInfoCircle}/>

                    </Tooltip>
                </div>
                <div className={"px-3"}>
                    <Tooltip placement={"bottom"} content={
                        <div className="flex items-center tex-xs">
                        <span>
                            <FormattedMessage id="poi.legend.description.multimedia-available"/>
                        </span>
                        </div>
                    }>
                        <FontAwesomeIcon size={"xs"} color={"gray"} icon={faPlayCircle}/>
                    </Tooltip>
                </div>


                {/*<div className="flex items-center tex-xs">*/}
                {/*    <FontAwesomeIcon size={"xs"} icon={faDiamond} color={"white"}/>&nbsp;*/}
                {/*    <span>*/}
                {/*        <FormattedMessage id="poi.legend.description.important-asset"/>*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className="flex items-center tex-xs">*/}
                {/*    <FontAwesomeIcon size={"xs"} icon={faServer} color={"white"}/>&nbsp;*/}
                {/*    <span>*/}
                {/*        <FormattedMessage id="poi.legend.description.monitoring-data"/>*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className="flex items-center tex-xs">*/}
                {/*    <FontAwesomeIcon size={"xs"} icon={faCube} color={"white"}/>&nbsp;*/}
                {/*    <span>*/}
                {/*        <FormattedMessage id="poi.legend.description.3d-scene"/>*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className="flex items-center tex-xs">*/}
                {/*    <FontAwesomeIcon size={"xs"} icon={faMapMarked} color={"white"}/>&nbsp;*/}
                {/*    <span>*/}
                {/*        <FormattedMessage id="poi.legend.description.geolocation"/>*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className="flex items-center tex-xs">*/}
                {/*    <FontAwesomeIcon size={"xs"} icon={faInfoCircle} color={"white"}/>&nbsp;*/}
                {/*    <span>*/}
                {/*        <FormattedMessage id="poi.legend.description.additional-info"/>*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className="flex items-center tex-xs">*/}
                {/*    <FontAwesomeIcon size={"xs"} icon={faPlayCircle} color={"white"}/>&nbsp;*/}
                {/*    <span>*/}
                {/*        <FormattedMessage id="poi.legend.description.multimedia-available"/>*/}
                {/*    </span>*/}
                {/*</div>*/}
            </div>

        </>
    );
};
