import {useStore} from "../../data/state/store";
import PoIModel from "../../data/state/models/PoIModel";
import {clone, Instance} from "mobx-state-tree";
import {
    convertPoILinkTo3DViewer,
    convertPoiListTo3DViewer,
    convertToPoIMarkerAlert,
    getParentScenePoI,
    getPoIAscendants
} from "../../utils/poiUtils";
import useLogger, {LogLevel} from "./Logger";
import {UnityService} from "../../data/services/unityService";
import {DEFAULT_CAMERA_TRAVEL_SPEED} from "../../config/global";
import PoILink from "../../data/state/models/PoILink";
import PoILinkModel from "../../data/state/models/PoILink";

const HOOK_NAME = "PoIHook"

const usePoI = () => {

    const {poiStore, viewStore, minimapStore} = useStore()
    const {logger} = useLogger()

    const selectPoI = (poi: Instance<typeof PoIModel>) => {

        // Buscamos los ascendientes del PoI
        const ascendants = getPoIAscendants(poiStore.tree!, poi)
        // Y buscamos el primer ascendiente con escena.
        const parentScenePoI = getParentScenePoI(poiStore.tree!, poi)

        logger(`Selected PoI with id: ${poi.id} has parent scene ${parentScenePoI?.asset3DURI}`, LogLevel.INFO, HOOK_NAME)

        // MANEJAMOS LA ESCENA QUE DEBEMOS CARGAR.
        // Caso 1: Si hay escena padre, y el propio PoI no tiene escena, cargamos la escena del PoI padre.
        if (parentScenePoI) {
            viewStore.setSceneURI(parentScenePoI.asset3DURI!)
            poiStore.setScenePoI(clone(parentScenePoI))
        }
        // Caso 2: Resto de casos, cargamos la escena raíz por defecto.
        else {
            const rootScene = poiStore.tree?.asset3DURI
            if (!rootScene) {
                logger("No root scene found", LogLevel.ERROR)
                return
            }
            viewStore.setSceneURI(rootScene)
            poiStore.setScenePoI(clone(poiStore.tree!))
        }

        // Actualizamos los marcadores del minimapa con los hijos del PoI seleccionado
        if (poi.children) {
            const markers = poi.children.map(poi => clone(poi))
            minimapStore.setMarkers(markers)
        }

        poiStore.setSelectedPoI(clone(poi))
        poiStore.setPoIBreadcrumb(ascendants)
        viewStore.setExpandedNodes(ascendants?.map(poi => poi.id) || [])
    }

    const loadPoIModel = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
                          poi: Instance<typeof PoIModel>) => {

        if (poi.loadsNewScene && poi.asset3DURI) {

            if (poi.asset3DURI === viewStore.view.currentScene) {
                logger(`PoI ${poi.id} scene already loaded`, LogLevel.WARNING)
                return Promise.resolve()
            }

            logger(`PoI with id: ${poi.id} loads new scene: ${poi.asset3DURI}`, LogLevel.INFO, HOOK_NAME)

            viewStore.setSceneLoaded(false)
            viewStore.setSceneURI(poi.asset3DURI)

            return UnityService.loadModel(unityMessenger, poi.asset3DURI)
        } else {

            logger(`PoI with id: ${poi.id} does not load new scene`, LogLevel.WARNING)

            return Promise.resolve()
        }

    }

    const loadModelFromURL = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
                              url: string) => {

        logger("Loading scene: " + url, LogLevel.INFO, HOOK_NAME)

        viewStore.setSceneLoaded(false)

        return UnityService.loadModel(unityMessenger, url)
    }

    const sendPoIList = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
                         poi: Instance<typeof PoIModel>) => {

        if (!poi.children) {
            logger("PoI has no children", LogLevel.INFO, HOOK_NAME)
            return Promise.resolve()
        }

        logger(`Sending PoI list with ${poi.children.length} elements for PoI with id: ${poi.id}`, LogLevel.INFO, HOOK_NAME)

        let poiList = "";

        poiList = convertPoiListTo3DViewer(poi.children)


        return UnityService.sendPoIList(unityMessenger, poiList)

    }

    const sendPoIMarker = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
                         poi: Instance<typeof PoIModel>) => {

        logger(`Sending marker of PoI with id: ${poi.id}`, LogLevel.INFO, HOOK_NAME)

        let poiList = "";

        poiList = convertPoiListTo3DViewer([poi])

        return UnityService.sendPoIList(unityMessenger, poiList)

    }

    const removeMarkers = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void) => {

        logger("Removing markers sending empty string", LogLevel.INFO, HOOK_NAME)
        return UnityService.sendPoIList(unityMessenger, "")
    }

    const goToPoIPosition = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
                             cameraPosition: any, cameraTargetPosition: any) => {

        const cameraPositionAndTravel = {
            camera_position: cameraPosition,
            camera_target: cameraTargetPosition,
            travel_duration: DEFAULT_CAMERA_TRAVEL_SPEED
        }
        return UnityService.goToPoi(unityMessenger, JSON.stringify(cameraPositionAndTravel))
    }

    const sendPoIMarkerAlerts = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
                         poi: Instance<typeof PoIModel>[]) => {

        if (!poi || poi.length === 0) {
            logger("No PoI markers to send", LogLevel.INFO, HOOK_NAME)
            return Promise.resolve()
        }

        logger(`Sending PoI marker alerts' list with ${poi.length} elements`, LogLevel.INFO, HOOK_NAME)

        let poiList = [];

        poiList = convertToPoIMarkerAlert(poi)

        for (let poi of poiList) {
            UnityService.setPoIMarkerBlinking(unityMessenger, JSON.stringify(poi)).then(r =>
                logger(`PoI marker alert sent`, LogLevel.INFO, HOOK_NAME));
        }

        return Promise.resolve()
    }

    const drawPoILinks = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
                          poi: Instance<typeof PoIModel> | undefined) => {


        if (!poi || !poi.linksToRender || poi.linksToRender.length === 0) {
            logger("No PoI links to draw", LogLevel.INFO, HOOK_NAME)
            return Promise.resolve()

        }

        logger(`Drawing PoI links for PoI with id: ${poi.id}`, LogLevel.INFO, HOOK_NAME)

        // eslint-disable-next-line array-callback-return
        let poiLinks = poi.linksToRender.map(link => convertPoILinkTo3DViewer(link));

        for (let poiLink of poiLinks) {
            console.log(JSON.stringify(poiLink))
            UnityService.drawPoILinks(unityMessenger, JSON.stringify(poiLink)).then(r =>
                logger(`PoI link ${poiLink.id} drawn`, LogLevel.INFO, HOOK_NAME));
        }

        return Promise.resolve()

    }

    const removePoILinks = (unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void) => {
        logger(`Removing PoI links`, LogLevel.INFO, HOOK_NAME)
        return UnityService.removePoILinks(unityMessenger)
    }


    return {removePoILinks, drawPoILinks, selectPoI, loadPoIModel, loadModelFromURL, sendPoIList, goToPoIPosition, removeMarkers, sendPoIMarker, sendPoIMarkerAlerts}

};

export default usePoI;
