import {Button, TextInput} from "flowbite-react";
import {useStore} from "../../../data/state/store";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {SCREEN_PATHS} from "../../../config/paths";
import useLogger, {LogLevel} from "../../hooks/Logger";
import {faCube} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const Login = observer(() => {

    const {logger} = useLogger();
    const {authStore} = useStore();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginResult, setLoginResult] = useState(-1);

    const handleLogin = () => {
        return (e: any) => {
            e.preventDefault();

            authStore.login(email, password).then(r => {
                const result = authStore.setCredentials(r);

                setLoginResult(result);

                if (result === 1) {
                    setTimeout(() => {
                        navigate(SCREEN_PATHS.VIEWER);
                    }, 1000)
                } else if (result === 0) {
                } else if (result === -1) {
                    setTimeout(() => {
                        navigate(SCREEN_PATHS.VIEWER);
                    }, 1000)
                }

            });
        }
    }

    useEffect(() => {
        // Every time the component is mounted, we check if the user is already logged in
        authStore.initialize();

        if (authStore.isAuthenticated) {
            logger("Already authenticated, navigating to viewer", LogLevel.INFO);
            navigate(SCREEN_PATHS.VIEWER);
        }
    });

    return (
        <>
            <div className="bg-gray-800 flex items-center justify-center min-h-screen">

                <div className="min-h-screen flex items-center bg-opacity-30 px-2.5 rounded-md text-white">
                    <form className="flex flex-col gap-4" onSubmit={handleLogin()}>

                        <h2 className="text-4xl font-extrabold dark:text-white">
                            <FontAwesomeIcon icon={faCube}/>&nbsp;
                            Twinhub
                        </h2>
                        <p className={"text-center"}>Please, provide your credentials</p>
                        <div>
                            <TextInput addon="@" id="email1" type="email" placeholder="Type your email" required
                                       onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div>
                            <TextInput id="password1" type="password" placeholder="Type your password" required
                                       onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <Button type="submit">Submit</Button>
                        <h1 className={"text-center " +
                            "" + (authStore.loading ? "text-gray-400" : "") +
                            "" + (loginResult === 0 ? "text-red-400" : "") +
                            "" + (loginResult === 1 ? "text-green-400" : "") +
                            ""}>
                            {authStore.loading ? "Login in..." : ""}
                            {loginResult === -1  && !authStore.loading ? " " : ""}
                            {loginResult === 0  && !authStore.loading ? "Bad credentials" : ""}
                            {loginResult === 1  && !authStore.loading ? "Login successful!" : ""}

                        </h1>
                    </form>
                </div>


                <footer className="shadow bg-gray-900 fixed bottom-0 w-full text-white">
                    <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <a href="https://signalsoftware.es/"
                               className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                                <img src={require("../../../assets/multimedia/imgs/logos/logo_signal2_bn.png")} className="h-8"
                                     alt="Signal Software Logo"/>
                            </a>
                            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium sm:mb-0 text-gray-400">
                                <li>
                                    <a target="_blank" href="mailto:info@signalsoftware.es" className="hover:underline">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8"/>
                        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">©
                            2025&nbsp;<a
                            href="https://signalsfotware.es/"
                            className="hover:underline">Signal Software™</a>. All Rights Reserved.</span>
                    </div>
                </footer>


            </div>


        </>
    );
});
