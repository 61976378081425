import {ThreeDViewer} from "../../components/3DViewer/ThreeDViewer";
import "./styles.css";
import PoIMenu from "../../components/PoIMenu/PoIMenu";
import {TopMenu} from "../../components/TopMenu/TopMenu";
import {useUnityContext} from "react-unity-webgl";
import {UNITY_CONFIG} from "../../../config/unity";
import {BottomNavigationMenu} from "../../components/BottomNavigationMenu/BottomNavigationMenu";
import {useStore} from "../../../data/state/store";
import {Drawer} from "flowbite-react";
import {observer} from "mobx-react";
import {useEffect} from "react";
import {LogLevel} from "../../hooks/Logger";
import useLogger from "../../hooks/Logger";
import {SPLASH_LOGO} from "../../../config/global";
import PoIGrafanaData from "../../components/PoIMenu/PoINode/PoIGrafanaData/PoIGrafanaData";



export const Viewer = observer(() => {

    const {viewStore, globalStore} = useStore()
    const {logger} = useLogger()

    // UNITY
    const {
        unityProvider,
        isLoaded,
        addEventListener,
        removeEventListener,
        sendMessage,

    } = useUnityContext(UNITY_CONFIG);

    const handleClosePoINavigation = () => {
        viewStore.togglePoINavigationPanel()
    }

    useEffect(() => {

        if (isLoaded) {
            logger("Unity loaded", LogLevel.INFO)
        }

    }, [isLoaded]);

    return (
        <>

            <div className="flex flex-col h-full">
                <TopMenu/>

                <div className="flex flex-grow max-h-full">

                    {viewStore.view.poiNavigationDrawer && (
                        <Drawer open={viewStore.view.showPoINavigation} onClose={handleClosePoINavigation}>
                            <Drawer.Items className={"p-0"}>
                                <PoIMenu unityMessenger={sendMessage}/>
                            </Drawer.Items>
                        </Drawer>
                    )}

                    {!viewStore.view.poiNavigationDrawer && (
                        <div className="md:w-1/4 lg:w-1/5 h-full relative overflow-hidden">
                            {/*<Tabs aria-label="Info">*/}
                            {/*    <Tabs.Item active title="Datos" icon={MdDashboard}>*/}
                            {/*        <DataPanel_OLD/>*/}

                            {/*    </Tabs.Item>*/}
                            {/*    <Tabs.Item title="Navegación" icon={FaFolderTree}>*/}
                            {/*        <PoIMenu unityMessenger={sendMessage}/>*/}
                            {/*    </Tabs.Item>*/}
                            {/*</Tabs>*/}
                            <PoIMenu unityMessenger={sendMessage}/>
                        </div>
                    )}

                    <div className="w-full md:w-3/4 lg:w-4/5 h-full relative">

                        {(!isLoaded || !viewStore.view.sceneLoaded) && (
                            <div role="status"
                                 className="z-50 w-full h-full space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                                <div
                                    className="flex items-center justify-center w-full h-full bg-gray-300 sm:w-full dark:bg-gray-700">

                                    <div className={"w-1/4"}>
                                        <img src={SPLASH_LOGO} alt="logo" className="w-100"/>
                                    </div>

                                </div>
                            </div>
                        )}

                        <ThreeDViewer
                            unityMessenger={sendMessage}
                            unityProvider={unityProvider} addEventListener={addEventListener}
                            removeEventListener={removeEventListener}/>

                    </div>
                    <div className={"sm:hidden"}>
                        <BottomNavigationMenu/>
                    </div>
                </div>
            </div>

        </>
    );
});
